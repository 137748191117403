import classNames from 'classnames'

interface H1Props {
  children?: React.ReactNode
  className?: string
}

const H1 = ({ children, className }: H1Props) => (
  <h1
    className={classNames(
      'text-[28px] font-black text-title-color font-montserrat',
      className,
    )}
  >
    {children}
  </h1>
)

export default H1
