interface DangerProps {
  children: React.ReactNode
  title: string | React.ReactNode
}
const Danger = ({ children, title }: DangerProps) => (
  <div className="rounded-md bg-red-50 p-4">
    <div className="ml-3">
      <h3 className="text-base font-semibold text-red-600">{title}</h3>
      <div>{children}</div>
    </div>
  </div>
)

export default Danger
