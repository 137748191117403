import { XCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

interface WarningProps {
  children: React.ReactNode
  title?: string
  className?: string
}
const Error = ({ children, title, className }: WarningProps) => (
  <div className={classNames('rounded-md bg-red-50 p-4', className)}>
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon
          className="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">{title || 'Error'}</h3>
        <div className="mt-2 text-sm text-yellow-700">{children}</div>
      </div>
    </div>
  </div>
)

export default Error
