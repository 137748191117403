const validateLength = (value: string, length: number, fieldName: string) => {
  if (value?.length > length) {
    return Promise.reject(
      new Error(
        `${fieldName}'s number of characters should be less or equal then ${length}`,
      ),
    )
  }
  return Promise.resolve()
}

export const validateLength50 = async (_: any, value: string) => {
  await validateLength(value, 50, _.field)
}
