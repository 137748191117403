import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

interface WarningProps {
  children: React.ReactNode | string
  title?: string
}
const Warning = ({ children, title }: WarningProps) => (
  <div className="rounded-md bg-yellow-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationCircleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        {title && (
          <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
        )}
        <div className="mt-2 text-sm text-yellow-700">{children}</div>
      </div>
    </div>
  </div>
)

export default Warning
