import ConfirmModal from '@@/ConfirmModal'
import Button from '@@/Button'
import Notification from '@@/Notification'
import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { useApi } from '@coding4tomorrow/c4t-next-core'
import { Danger } from '@@/Alert'

const DeleteEvent = () => {
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const router = useRouter()

  const { request: deleteEventRequest, loading: isDeletingEvent } = useApi(
    'projects.deleteProjectForUser',
    {
      id: router?.query?.id,
    },
  )
  const deleteEvent = useCallback(async () => {
    setConfirmVisible(false)
    const response = await deleteEventRequest()
    if (response) {
      setNotificationVisible(true)
      router.push('/')
    }
  }, [deleteEventRequest, router])
  return (
    <Danger title="BE CAREFULL">
      <p className="text-sm font-normal text-red-700 mb-3">
        Aww yeah, you successfully read this important alert message. This
        example text is going to run a bit longer so that you can see how
        spacing within an alert works with this kind of content.
      </p>
      <Button
        className="m-auto"
        buttonType="danger"
        loading={isDeletingEvent}
        onClick={() => setConfirmVisible(true)}
        type="button"
        data-testid="delete-event"
      >
        Delete this event
      </Button>
      <ConfirmModal
        okText="Yes"
        cancelText="No"
        visible={confirmVisible}
        onCancel={() => setConfirmVisible(false)}
        onOk={deleteEvent}
      >
        you sure you want to delete this Event ? This action can not be undone.
      </ConfirmModal>
      <Notification
        type="success"
        visible={notificationVisible}
        setVisible={setNotificationVisible}
        title="Success"
        description="this event was deleted successfully"
      />
    </Danger>
  )
}

export default DeleteEvent
