import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

interface DangerWarningProps {
  children: React.ReactNode | string
  title?: string
  className?: string
}
const DangerWarning = ({ children, title, className }: DangerWarningProps) => (
  <div className={classNames('rounded-md bg-red-100 p-4', className)}>
    <div className="flex items-center">
      <div className="flex-shrink-0">
        <ExclamationTriangleIcon
          className="h-5 w-5 text-red-600 mr-4"
          aria-hidden="true"
        />
      </div>
      <div>
        {title && <h3 className="text-sm font-medium text-red-800">{title}</h3>}
        <div className="mt-2 font-semibold text-sm text-red-600 text-left">
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default DangerWarning
