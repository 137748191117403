import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

interface SuccessProps {
  onClose?: () => void
  children: string | React.ReactNode
  className?: string
}
const Success = ({ onClose, children, className }: SuccessProps) => (
  <div
    className={classNames('rounded-md bg-green-50 p-4', className)}
    data-testid="success"
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-green-800">{children}</p>
      </div>
      {onClose && (
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              onClick={onClose}
              type="button"
              className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
)

export default Success
