export const planTypes: Record<string, string> = {
  starter: 'starter',
  creator: 'creator',
  premium: 'premium',
  pro: 'pro',
}

export const isStarter = (planType: string) => planType === planTypes.starter
export const isCreator = (planType: string) => planType === planTypes.creator
export const isPremium = (planType: string) => planType === planTypes.premium
export const isPro = (planType: string) => planType === planTypes.pro

export const getPlanOptions = () =>
  Object.keys(planTypes).map((key) => ({
    label: planTypes[key],
    value: planTypes[key],
  }))
