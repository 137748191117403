import { Form } from 'antd'
import Spinner from '@@/Spinner'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useFormApi, useUser } from '@coding4tomorrow/c4t-next-core'
import Notification from '@@/Notification'
import styled from 'styled-components'
import DeleteEvent from '@/Events/DeleteEvent'
import { useSideBarLayoutContext } from '~/context/SideBarLayoutContext'
import slugify from 'slugify'
import { useRouter } from 'next/router'
import Button from '@@/Button'
import useCanUpdate from '~/hooks/useCanUpdate'
import useConfirmLeavePage from '~/hooks/useConfirmLeavePage'
import { isStarter } from '~/types/plans'
import { validateLength50 } from '~/helpers/validate-length'
import FloatingLabelInput from '@@/FloatingLabelInput'
import FloatingLabelTextArea from '@@/FloatingLabelTextArea'
import Centered from '@@/Centered'
import CardSelector from '@@/CardSelector'
import { eventPrivacyOptions } from '~/types/event'
import { gleamerAppBaseUrl } from '~/helpers/urls'

interface CreateEventFormTypes {
  setIsOpen?: any
  mutateEvents?: () => void
  initialData?: any
  mutateEvent?: () => void
}

const EventForm = ({
  mutateEvents,
  setIsOpen,
  initialData,
  mutateEvent,
}: CreateEventFormTypes) => {
  const router = useRouter()
  const [form] = Form.useForm()
  const { user } = useUser()
  const [updateNotificationVisible, setUpdateNotificationVisible] =
    useState(false)
  const [eventIdDeffered, eventIdDefferedSet] = useState(false)
  const [useDefaultDomain, setUseDefaultDomain] = useState(true)
  const [customDomain, setCustomDomain] = useState('')
  const initialEventType = useMemo(
    () =>
      eventPrivacyOptions.find((type) => type.id === initialData?.type) ||
      eventPrivacyOptions[0],
    [initialData],
  )
  const [eventType, setEventType] = useState(initialEventType)
  const initialDataModified = useMemo(
    () => ({
      ...initialData,
      type: initialEventType,
      description: initialData?.description || '',
    }),
    [initialData, initialEventType],
  )
  const { disabled, handleValuesChanges, setDisabled } = useCanUpdate(
    initialDataModified,
    form,
  )
  const { ConfirmLeavePage } = useConfirmLeavePage(!disabled)

  const { mutateEvents: mutateSideBarEvents, isLoadingEvents } =
    useSideBarLayoutContext()

  useEffect(() => {
    if (initialData) {
      if (initialDataModified?.domain !== gleamerAppBaseUrl) {
        setUseDefaultDomain(false)
      }
      setCustomDomain(initialDataModified?.domain)
    }
    if (initialDataModified) {
      form.setFieldsValue(initialDataModified)
    }
  }, [initialDataModified, form, initialData])

  const afterApiCall = useCallback(
    async (response) => {
      if (response) {
        setDisabled(true)
        if (initialData) {
          setUpdateNotificationVisible(true)
          mutateSideBarEvents?.()
          mutateEvent?.()
        } else {
          mutateEvents?.()
          setIsOpen?.(false)
          setTimeout(() => router.push(`/events/${response?._id}/stages`), 500)
        }
      }
    },
    [
      mutateEvent,
      mutateEvents,
      setIsOpen,
      initialData,
      mutateSideBarEvents,
      router,
      setDisabled,
    ],
  )

  const beforeApiCall = useCallback(
    (values) => ({ ...values, type: values.type.id, domain: customDomain }),
    [customDomain],
  )

  const {
    ErrorComponent,
    isLoading: loading,
    onSubmit: onFinish,
  } = useFormApi({
    form,
    api: initialData
      ? {
          path: 'projects.updateProjectForUser',
          params: {
            id: initialData?._id,
          },
        }
      : 'projects.create',
    beforeApiCall,
    afterApiCall,
  })

  const eventIdSlugifier = useCallback(
    (value: string) =>
      slugify(value, {
        lower: true,
        strict: true,
      }),
    [],
  )

  const eventIdGenerator = useCallback(
    (eventName: string) => {
      const eventId = eventIdSlugifier(eventName)

      form.setFieldsValue({
        eventId,
      })

      if (!eventName?.length) {
        form.setFieldsValue({
          eventId: '',
        })
      }
    },
    [eventIdSlugifier, form],
  )

  const handleEventTypeChange = useCallback(
    async (value) => {
      setEventType(value)
      form.setFieldsValue({ type: value })
      handleValuesChanges({ type: value })
    },
    [handleValuesChanges, form],
  )
  const handleUseCustomDomainChange = useCallback(
    (e) => {
      const { checked } = e.target
      if (checked) {
        setUseDefaultDomain(true)
        setCustomDomain('')
        handleValuesChanges({ domain: '' })
      } else {
        if (initialData?.domain) {
          handleValuesChanges({ domain: initialData?.domain })
          setCustomDomain(initialData?.domain)
        }
        setUseDefaultDomain(false)
      }
    },
    [handleValuesChanges, initialData?.domain],
  )

  if (isLoadingEvents) {
    return <Spinner />
  }

  return (
    <>
      {ErrorComponent && (
        <div className="mb-3.5">
          <ErrorComponent />
        </div>
      )}
      <FormContainer
        layout="vertical"
        className="space-y-6"
        form={form}
        onFinish={onFinish}
        data-testid="event-form"
        onValuesChange={handleValuesChanges}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Event name is required',
            },
            {
              validator: validateLength50,
            },
          ]}
        >
          <FloatingLabelInput
            label="Event name"
            required
            onChange={(e: any) => {
              if (!initialData && !eventIdDeffered) {
                eventIdGenerator(e.target.value?.toLocaleLowerCase()?.trim())
              }
            }}
            maxLength={120}
            data-testid="event-name"
          />
        </Form.Item>
        <div>
          <div className="flex items-center content-center">
            <Form.Item
              className="flex-1"
              name="eventId"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid Event Id',
                  validator(_, value) {
                    try {
                      if (!value) {
                        return Promise.reject()
                      }

                      const url = new URL(
                        `https://${value}.${gleamerAppBaseUrl}` as string,
                      )

                      if (url) {
                        return Promise.resolve()
                      }

                      return Promise.reject()
                    } catch (error) {
                      return Promise.reject()
                    }
                  },
                },
              ]}
            >
              <FloatingLabelInput
                disabled={initialData && isStarter(user?.plan)}
                label="Event ID"
                required
                onChange={(e: any) => {
                  eventIdGenerator(e.target.value?.toLocaleLowerCase()?.trim())
                  eventIdDefferedSet(true)
                }}
              />
            </Form.Item>
            <span className="ml-1 mr-10 text-text-color">
              .{gleamerAppBaseUrl}
            </span>
          </div>
          <div className="flex items-center mb-4">
            <span className="mr-4 text-sm text-neutral-500">
              Use default domain {`(.${gleamerAppBaseUrl})`}
            </span>
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-neutral-900 focus:ring-neutral-900 cursor-pointer"
              checked={useDefaultDomain}
              onChange={handleUseCustomDomainChange}
            />
          </div>
          {!useDefaultDomain && (
            <Form.Item
              name="domain"
              rules={[
                {
                  required: true,
                  message: 'Domain is required',
                },
              ]}
            >
              <FloatingLabelInput
                label="Domain"
                required
                maxLength={120}
                value={customDomain}
                onChange={(e: any) => setCustomDomain(e.target.value)}
              />
            </Form.Item>
          )}
          <Form.Item name="description">
            <FloatingLabelTextArea
              rows={5}
              cols={50}
              label="Event description"
            />
          </Form.Item>
          <Form.Item name="type">
            <CardSelector
              setSelectedItem={handleEventTypeChange}
              selectedItem={eventType}
              items={eventPrivacyOptions}
              label="Visibility"
              display="vertical"
            />
          </Form.Item>
        </div>
        <Centered>
          <Button
            disabled={disabled}
            data-testid="submit-event"
            type="submit"
            className="w-auto m-auto"
            loading={loading}
          >
            {initialData ? 'Update your event' : 'Create your event'}
          </Button>
        </Centered>
        {initialData && <DeleteEvent />}
      </FormContainer>
      <Notification
        type="success"
        visible={updateNotificationVisible}
        setVisible={setUpdateNotificationVisible}
        title="Success"
        description="Event updated successfully"
      />
      <ConfirmLeavePage />
    </>
  )
}

const FormContainer = styled(Form)`
  .ant-form-item-explain-error {
    text-align: left;
  }
`

export default EventForm
