import { InformationCircleIcon } from '@heroicons/react/24/solid'

interface InformationProps {
  children: React.ReactNode
  title?: string
}
const Information = ({ children, title }: InformationProps) => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-2">
        {title && (
          <h3 className="text-sm font-medium text-blue-800 mb-2">{title}</h3>
        )}
        <div className="ml-3 text-sm text-blue-700 flex-1 md:flex md:justify-between">
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default Information
