import { InformationCircleIcon } from '@heroicons/react/24/solid'
import FlowbiteTooltip from '@@/flowbite-custom/Tooltip'
import classNames from 'classnames'

interface InformationOnHoverProps {
  message: string | React.ReactNode
  className?: string
  placement?: 'top' | 'right' | 'bottom' | 'left' | 'auto'
}
const InformationOnHover = ({
  message,
  className,
  placement,
  ...rest
}: InformationOnHoverProps) => (
  <FlowbiteTooltip
    content={message}
    trigger="click"
    placement={placement}
    {...rest}
  >
    {/** the button is added to make the tooltip closable when we click
     * everywhere in the app
     */}
    <button type="button">
      <InformationCircleIcon
        className={classNames(
          'h-5 w-5 text-cornflower cursor-pointer',
          className,
        )}
      />
    </button>
  </FlowbiteTooltip>
)

export default InformationOnHover
